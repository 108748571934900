import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { Container, Row, Col } from 'reactstrap'

import prohibidoImg from 'img/vectores/prohibido.svg'
import joyasImg from 'img/vectores/joyas.svg'
import animalesImg from 'img/vectores/animales.svg'
import arteImg from 'img/vectores/arte.svg'
import drogasImg from 'img/vectores/drogas.svg'
import armasImg from 'img/vectores/armas.svg'
import vinosImg from 'img/vectores/vinos.svg'

type RestriccionesEnvioProps = PageProps
interface RestriccionesEnvioState {
  mounted: boolean
}
class RestriccionesEnvio extends Component<RestriccionesEnvioProps, RestriccionesEnvioState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <Container fluid className="hero-rosa hero-rosa-paq pad">
              <Row>
                <Col sm={12}>
                  <h1 className="big-white">Restricciones de envío</h1>
                </Col>
              </Row>
            </Container>
            <Container fluid className="desc-restr">
              <Row>
                <Col sm={{ size: 8, offset: 2 }}>
                  <h2>En All Abordo éstos son los artículos que no podemos transportar por disposiciones de la Ley:</h2>
                </Col>
              </Row>
            </Container>

            <Container className="restricciones pad">
              <Row>
                <Col sm={12}>
                  <br />
                  <br />
                  <h1 className="big-gray">
                    <img src={prohibidoImg} /> Mercancías no transportables
                  </h1>
                  <br />
                  <br />
                </Col>
                <Col sm={6}>
                  <ul>
                    <li>
                      <img src={joyasImg} />
                      Joyas
                    </li>
                    <li>
                      <img src={animalesImg} />
                      Animales
                    </li>
                    <li>
                      <img src={arteImg} />
                      Piezas de arte
                    </li>
                  </ul>
                </Col>
                <Col sm={6}>
                  <ul>
                    <li>
                      <img src={drogasImg} />
                      Drogas, estupefacientes
                    </li>
                    <li>
                      <img src={armasImg} />
                      Armas
                    </li>
                    <li>
                      <img src={vinosImg} />
                      Vinos y licores
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default RestriccionesEnvio
